import axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from '../../helpers/auth-header';

import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_IES_CHART_DETAIL_BY_QUERY, GET_IES_EARLY_EXITS_DETAIL_BY_QUERY } from './types';
import Config from '../../config';

import {
  getIESChartDetailReportByQuerySuccess,
  getIESChartDetailReportByQueryError,
} from './actions';
import { buildUrl, hasClientServices } from '../../helpers/Utils';

const getIESChartDetailReportByQueryRequest = async payload => {
  return axios.post(`${Config.apiServiceHost}/api/Dashboard/iesChartDetailReport`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const getAverageTimeClientRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Dashboard/averageTimeClient`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};
const exportAverageTimeClientRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/Dashboard/exportAverageTimeClient`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const exportSolgenReferralRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/Dashboard/exportSolgenReferralXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getIESEarlyExitsDetailReportByQueryRequest = async payload => {
  return axios.post(`${Config.apiServiceHost}/api/Dashboard/iesEarlyExitsDetailReport`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const getSolgenReferralDetailReportByQueryRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Dashboard/solgenReferrals`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const exportIESClientReportToExcelReqeust = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/exportIesChartDetailReportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const exportEarlyExitsDetailToExcelReqeust = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/exportIesEarlyExitsDetailReportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getJobSeekerRetainedByEmployerExportToExcelRequest = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/jobSeekerRetainedByEmployerExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getReferralInChartDataExportRequest = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/referralInChartDataExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getClientCompletedSurveyChartDataExportRequest = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/clientCompletedSurveyChartDataExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getClientSatisfactionBySpecializePopulationChartDataExportRequest = async (
  query,
  fileName
) => {
  return axios
    .post(
      `${Config.apiServiceHost}/api/Dashboard/clientSatisfactionBySpecializePopulationChartDataExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getHiringPerSectorChartDataExportRequest = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/Dashboard/hiringPerSectorChartDataExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getPlannedItemBudgetTrackingDataExportRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServiceHost}/api/Dashboard/plannedItemBudgetTrackingDataExport`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        if (!hasClientServices()) {
          const { data } = res;
          FileSaver.saveAs(new Blob([data]), fileName);
        }
      }
    });
};

const getInsightChartDataExportToExcelExportRequest = async (query, fileName) => {
  return axios
    .get(`${Config.apiServerHost}/api/Dashboard/insightChartDataExportToExcel`, {
      params: query,
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getCaseActivityStateDataExportRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/Dashboard/caseActivityStateDataExport`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

function* getIESChartDetailReportByQuery({ payload }) {
  try {
    const response = yield call(getIESChartDetailReportByQueryRequest, payload);
    yield put(getIESChartDetailReportByQuerySuccess(response.data));
  } catch (error) {
    yield put(getIESChartDetailReportByQueryError(error.response.statusText));
  }
}

function* getIESEarlyExitsDetailReportByQuery({ payload }) {
  try {
    const response = yield call(getIESEarlyExitsDetailReportByQueryRequest, payload);
    yield put(getIESChartDetailReportByQuerySuccess(response.data));
  } catch (error) {
    yield put(getIESChartDetailReportByQueryError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_IES_CHART_DETAIL_BY_QUERY, getIESChartDetailReportByQuery);
  yield takeEvery(GET_IES_EARLY_EXITS_DETAIL_BY_QUERY, getIESEarlyExitsDetailReportByQuery);
}

export {
  exportAverageTimeClientRequest,
  getAverageTimeClientRequest,
  exportIESClientReportToExcelReqeust,
  getJobSeekerRetainedByEmployerExportToExcelRequest,
  getReferralInChartDataExportRequest,
  getInsightChartDataExportToExcelExportRequest,
  getCaseActivityStateDataExportRequest,
  getHiringPerSectorChartDataExportRequest,
  getClientSatisfactionBySpecializePopulationChartDataExportRequest,
  getClientCompletedSurveyChartDataExportRequest,
  exportEarlyExitsDetailToExcelReqeust,
  getPlannedItemBudgetTrackingDataExportRequest,
  getSolgenReferralDetailReportByQueryRequest,
  exportSolgenReferralRequest,
};
